import { useFrame } from '@react-three/fiber'
import { meshBounds, useGLTF, OrbitControls, Float, PerspectiveCamera, OrthographicCamera } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Canvas, useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { useTexture } from "@react-three/drei"
import { useHelper } from '@react-three/drei'
import { SpotLightHelper } from "three";
import ColorMap from './assets/Rock037_2K-JPG/Rock037_2K_Color.jpg'
import DisplacementMap from './assets/Rock037_2K-JPG/Rock037_2K_Displacement.jpg'
import RoughnessMap from './assets/Rock037_2K-JPG/Rock037_2K_Roughness.jpg'

import { Bloom, DepthOfField, EffectComposer, Glitch, Noise, Vignette } from "@react-three/postprocessing";
import { BlendFunction, DepthOfFieldEffect, GlitchMode } from 'postprocessing'

export default function Experience()
{
    const light = useRef()
    const cube = useRef()
    const plane = useRef()
    const props = useTexture({
        map: ColorMap,
        displacementMap: DisplacementMap,
        // normalMap: './assets/Rock037_2K-JPG/Rock037_2K_NormalDX.jpg',
        roughnessMap: RoughnessMap
        // aoMap: './assets/Rock037_2K-JPG/Rock037_2K_AmbientOcclusion.jpg',
    })

    const [yPosition, setYPosition] = useState(30)
    const [xPosition, setXPosition] = useState(0)

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    const camera = useRef()

    useEffect(() => {
        // props.map.wrapS = props.map.wrapT = THREE.RepeatWrapping;
        // props.map.offset.set( 3, 0 );
        // props.map.repeat.set( 4, 4 );
        camera.current.lookAt(0, -4, 0)
        window.document.addEventListener('scroll', eventHandler)
        
        if (!isMobile) {
            window.document.body.addEventListener('mousemove', mouseHandle)
        }
    }, [])

    useFrame(({ camera, clock }) => {
        plane.current.rotation._x = clock.getElapsedTime() * 1000.0
        // camera.zoom = Math.max(0.2, Math.cos(clock.getElapsedTime() / 10) ) + 1.7
        // camera.updateProjectionMatrix()
        
        // console.log(plane.current.rotation)
      })

    const eventHandler = (event) =>
    {
        setYPosition(25 + (window.scrollY / window.document.body.clientHeight) * 10)
        // cube.current.material.color.set(`hsl(${Math.random() * 360}, 100%, 75%)`)
    }

    const mouseHandle = (event) => {
        if (!isMobile) {
            setXPosition(Math.min(4, -1 + (event.clientX / window.document.body.clientWidth) * 4))
        }
    }
    
    return <>

<color args={ [ '#ffffff' ] } attach="background" />
    <Float 
      far={2000}
      speed={.5}
      floatIntensity={.3}
    //   rotationIntensity={[1, 1, 1]}
      floatingRange={[-2, 2]}>
        
    <PerspectiveCamera
      makeDefault
      zoom={1.8}
      ref={camera}
      fov={14}
      position={[xPosition/10, yPosition, .1]}

    />
    </Float>

        <Float floatIntensity={.2}>
            <spotLight ref={ light } color={new THREE.Color('#e39576')} position={ [ xPosition, 15, 0 ] } intensity={.5} />
        </Float>
        <ambientLight intensity={ .8} />
        <mesh   ref={plane} position-y={ - 1 } rotation-x={ - Math.PI * 0.5 } scale={ 10 }>
            <planeGeometry />
            <meshStandardMaterial roughness={.9} metalness={.3} toneMapped={ false } {...props} />
        </mesh>
 
        <EffectComposer  multisampling={ 4 }>
            <Bloom intensity={ 1 } luminanceThreshold={ .5 } />
            <Vignette offset={ 0.0 } darkness={ 1.2 } />
        </EffectComposer>
    </>
}