import './style.scss'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three'
import Experience from './Experience.jsx'
import MainLogo from './assets/img/ASHNIKKO_11.png'
import Form from './components/Form';
import Presave from './components/Presave';
import FanArt from './components/FanArt';
import Footer from './components/Footer';
import { motion } from "framer-motion"
import Lenis from '@studio-freight/lenis'
import { useEffect } from 'react'

const root = ReactDOM.createRoot(document.querySelector('#root'))


const App = () => {
    
  const lenis = new Lenis({
    duration: 1.4,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    direction: "vertical", // vertical, horizontal
    gestureDirection: "vertical", // vertical, horizontal, both
    smooth: true,
    mouseMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 1.6,
    infinite: false,
  });

  const raf = (time) => {
    lenis.raf(time);
    requestAnimationFrame(raf);
  };

  useEffect(() => {
    requestAnimationFrame(raf);
  }, []);
  const created = ({ gl }) =>
  {
      gl.setClearColor('#ff0000', 0)
  }


return (
  <>
    <Canvas
      className="canvas"
      gl={{
        antialias: true,
        toneMapping: THREE.ACESFilmicToneMapping,
      }}
      onCreated={created}
    >
      <Experience />
    </Canvas>

    <main>
      <motion.img
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 1,
          delay: 0.3,
        }}
        className="fullLogo"
        src={MainLogo}

        alt=""
      />
      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
        }}
      >
        Tente de gagner 2 places pour son concert à Paris !</motion.h1>
      <Presave />
      {/* <Form /> */}
      {/* <FanArt /> */}
      <Footer />
    </main>
  </>
);
}

root.render(
    <App />
)