import React, { useEffect, useState } from 'react'
import Separation from '../../assets/img/separation.webp'
import CoverAshnikko from '../../assets/img/Faberge-Egg-Square2.jpg'
import './index.scss'
import useScript from "../../hooks/useScript";
import { AnimatePresence, motion } from "framer-motion"

const Presave = () => {
  const [isCompleteForm, setIsCompleteForm] = useState(false)

    useEffect(() => {
      var connect_button = new window.WMGConnect(".wmg-buttons", {
        // Would you like a popup "Thank you" message to automatically appear once the user has been acquired?
        opt_in_thank_you_enabled: false,
        opt_in_form_target: "#formulaire",

        // Data sources are automatically generated based on how the campaign is set up but you can specify a custom one below
        // exacttarget_datasource: 'WMG_EdSheeran_PreSave'    
      });
  
      connect_button.setCallback(function(connect_data){
        // Callback code
        console.log(connect_data);
        setIsCompleteForm(true)
        
        setTimeout(() => {
          document.querySelector('.wmgsdk-modal').style.display = "none"
        }, 100)
      });
    }, [])
  
    return (
      <>
        <section className="presaveSection">
          <motion.img
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.8,
              delay: 1.2,
            }}
            src={CoverAshnikko}
            alt=""
          />

          <AnimatePresence>
            <motion.div
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.8,
                  delay: 1.2,
                }}
                className="column"
              >
                <h2 className='center'>
                Enregistre l'album «&nbsp;Weedkiller&nbsp;» de Ashnikko, et tente de gagner 2 places pour son concert à Paris le 24 novembre à l'Elysée Montmartre&nbsp;!
                </h2>
                <a className='enregistrerAlbum' href="https://storise.co/ashnikko-weedkiller">Enregistrer l'album</a>
              </motion.div>
          </AnimatePresence>
        </section>
        <motion.img
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.8,
            delay: 2.2,
          }}
          className="separator"
          src={Separation}
          alt=""
        />
        <motion.h1
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1,
            delay: 0.5,
          }}
          className='listenTitle'
        >
          Inscris-toi à la newsletter pour suivre l'actualité d'Ashnikko&nbsp;:</motion.h1>
        {!isCompleteForm && (
          <div id="formulaire"></div>
        )}

        {isCompleteForm && (
              <>
              <motion.p
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.8,
                }}
                className='participationOK'
              >
                Ton inscription a bien été prise en compte.<br />
Découvre le nouvel album «&nbsp;Weedkiller&nbsp;»&nbsp;!
              </motion.p>
              <a className="listenAlbum" href="https://WEA.lnk.to/AshnikkoWeedkiller">Écouter l'album</a>
              </>
            )}
        <motion.img
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.8,
            delay: 2.2,
          }}
          className="separator"
          src={Separation}
          alt=""
        />
        
      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
        }}
        className='listenTitle'
      >
        Écoute l'album Weedkiller&nbsp;!</motion.h1>
        
        <a 
        className="listenAlbum" href="https://WEA.lnk.to/AshnikkoWeedkiller">Écouter l'album</a> 
        
        <motion.img
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.8,
            delay: 2.2,
          }}
          className="separator"
          src={Separation}
          alt=""
        />
      </>
    );
}

export default Presave